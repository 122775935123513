import React, { useState } from 'react'
import { type User } from '../../../../store/slices/userSlice'
import useUserType from '../../../../hooks/UseUserType'
import { useNavigate } from 'react-router'
import { openChatByUserID } from '../../../../store/actionsCreators/chatActions'
import { useAppDispatch } from '../../../../hooks/redux'
import Avatar from '../../elements/Avatar'
import Icon from '../../../../app/icon/Icon'


interface ProfileCardProps {
    user: User
}
export default function ProfileCardNew({ user }: ProfileCardProps): JSX.Element {
    const { userType } = useUserType()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    return (
        <>
            <div className="d-flex flex-column bg-white rounded-4 shadow-sm overflow-hidden position-relative mb-4"
                style={{
                    height: 'auto',
                }}
                onClick={(e) => {
                    if ((e.target as HTMLDivElement).closest('#contact-user-button') == null) {
                        navigate(`/${userType.toLowerCase()}/profile/${user.id}`);
                    }
                }}
            >

                <div className="position-relative"
                    style={{
                        backgroundImage: `url(${user.Settings?.[0]?.WallpaperLink ?? '/assets/media/stock/s_default.jpg'})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        height: '60px'
                    }}

                >
                    <div className="overlay rounded-4 text-end shadow-sm"
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: 'auto',
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                            zIndex: 0,
                            pointerEvents: 'none'
                        }}
                    />

                    <div className="position-relative d-flex justify-content-between align-items-center"
                        style={{
                            height: '70px',
                            zIndex: 1,
                            padding: '1rem'
                        }}>
                        <div
                            style={{
                                width: '90px',
                                height: '90px',
                                borderRadius: '50%',
                                overflow: 'hidden',
                                border: '3px solid white',
                                position: 'absolute',
                                bottom: '-37px',
                                left: '14px'
                            }}
                        >
                            <Avatar image={user?.Type?.[0]?.Photo ?? undefined} size={'100%'} />
                        </div>
                    </div>
                </div>

                <div className="position-relative"
                    style={{
                        marginLeft: '110px',
                        marginTop: '6px',
                        zIndex: 1
                    }}
                >
                    <div className="pb-4 mb-1">
                        <div className="d-flex align-items-center justify-content-between text-gray-800 text-hover-primary fs-6 fw-bold lh-sm" style={{ wordBreak: 'break-word' }}>
                            <div className="me-auto" style={{ paddingRight: '50px', }}>
                                {user?.ContactInfo?.FirstName} {user?.ContactInfo?.FamilyName}
                                <span className={''}>
                                    {user?.Settings?.[0]?.GifLink && <img src={user?.Settings?.[0]?.GifLink} alt="" className="h-20px w-20px ms-2 rounded" />}
                                </span>
                            </div>
                            <span className="d-flex justify-content-between align-items-center me-2">
                                {user.id % 2 !== 1
                                    ?
                                    <>
                                        <Icon name={'verified'} className={'fs-1 text-warning'} />
                                        {(
                                            <span className="fs-5 badge badge-warning ms-1">{user.Settings?.[0]?.Performance ?? 3.3}</span>
                                        )}
                                    </>
                                    :
                                    <>
                                        <Icon name={'verified'} className={'fs-1 text-primary'} />
                                        {(
                                            <span className="fs-5 badge badge-success ms-1">{user.Settings?.[0]?.Performance ?? 4.7}</span>
                                        )}
                                    </>
                                }
                            </span>
                        </div>
                        <span className="text-gray-600 fw-semibold d-block fs-7 lh-1">{user.Settings?.[0]?.Company ?? ""}</span>
                    </div>
                </div>

                <div className={`text-gray-800 fs-7 lh-sm p-4 m-2  ls-1 lh-1 ${"bg-gray-100"} rounded rounded-4 `} style={{ wordBreak: 'break-word' }}>
                    {user?.About?.trim() && user?.About?.trim().length > 2 ? user?.About?.trim() : "Hey there! I am using BSO Club!"}
                </div>
            </div>
        </>
    )
}

//                 <span id="contact-user-button" onClick={() => {
//                     if (user.id !== store.getState().user.currentUser.id) {
//                         setLoading(true);
//                         dispatch(openChatByUserID(user.id))
//                             .then(({ id }: { id: any }) => {
//                                 setLoading(false);
//                                 if (id != null) {
//                                     navigate(`/${userType.toLowerCase()}/chats/${String(id)}`);
//                                 }
//                             });
//                     }
//                 }}>
//                     <Icon name="mail" className="text-gray-800 fs-2hx" />
//                 </span>