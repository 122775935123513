
import React, { useState, type Dispatch, type SetStateAction } from 'react'
import { useAppSelector } from '../../../../../hooks/redux'
import { type RootState } from '../../../../../store/store'
import Modal from 'antd/es/modal/Modal'
import { type ISettings, type User } from '../../../../../store/slices/userSlice'

import CloseIconBasic from '../../../../shared/elements/CloseIconBasic'
import { ava_blank } from '../../../../../urls'
import Avatar from '../../../../shared/elements/Avatar'
import Icon from '../../../../../app/icon/Icon'
import Markdown from 'react-markdown'
interface IProps {
    previewOpen?: boolean
    setPreviewOpen: Dispatch<SetStateAction<boolean>>
    previewUser: User
}
export default function OtherProfilesNEW({
    previewOpen,
    previewUser,
    setPreviewOpen,

}: IProps): JSX.Element {
    const userCONTACTS = (previewUser)?.Type?.[0]
    const userContactsINFO = (previewUser)?.ContactInfo
    const userSettings = previewUser?.Settings as ISettings[]

    const [imageUrl, setImageUrl] = useState<string>(
        userCONTACTS?.Photo ?? ava_blank
    )
    const licenseApproved = useAppSelector(
        (state: RootState) => state.agentStates.statusesApproved.licenseApproved
    )

    let fullName =
        (userContactsINFO?.FirstName ? userContactsINFO.FirstName : userCONTACTS?.Name) +
        ' ' +
        (userContactsINFO?.FamilyName ? userContactsINFO.FamilyName : '')
    fullName === 'undefined ' ? fullName = 'No name' : null
    const maxLength = 28


    const languages = userSettings?.[0]?.Languages


    return (
        <>
            <div className="d-flex flex-column bg-white rounded rounded-4 shadow-sm overflow-hidden position-relative">
                <div className="position-relative"
                    style={{
                        backgroundImage: `url(${userSettings?.[0]?.WallpaperLink ?? `/assets/media/stock/s_default.jpg`})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',

                    }}>

                    <div className="overlay rounded rounded-4 text-end shadow-sm"
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                            zIndex: 0,
                            pointerEvents: 'none'
                        }} />
                    <div className="position-relative d-flex justify-content-start align-items-center"
                        style={{
                            height: '140px',
                            zIndex: 1,
                            padding: '1rem'
                        }}>
                        <div
                            style={{
                                width: '130px',
                                height: '130px',
                                borderRadius: '50%',
                                overflow: 'hidden',
                                border: '4px solid white',
                                position: 'absolute',
                                bottom: '-70px',
                                left: '20px'
                            }}
                            onClick={() => { setPreviewOpen(true); }}
                        >
                            <Avatar image={imageUrl} size={'100%'} />
                        </div>
                    </div>
                    {<div className='' style={{
                        height: '35px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '140px',
                        position: 'absolute',
                        right: '10px',
                        top: '10px',
                        zIndex: 2
                    }} >
                        {/* <span className="fs-2x badge badge-danger">2.3 </span> */}
                        {userSettings?.[0].Performance && <span className="fs-2x badge badge-warning">{userSettings?.[0].Performance ?? "3.7"} </span>}
                        {/* <span className="fs-2x badge badge-success">4.7</span> */}
                    </div>}
                </div>

                <div className="p-4 pt-0" style={{ marginTop: '80px' }}>
                    <div className="d-flex align-items-center text-gray-800 text-hover-primary fs-3 fw-bold" style={{ wordBreak: 'break-word' }}>
                        {fullName}   <span className={'d-flex align-items-center ps-1'}>
                            {userSettings?.[0]?.GifLink && <img src={userSettings?.[0]?.GifLink} alt="" className="h-25px w-25px ms-2 rounded" />}
                            {/* {licenseApproved
                                ? <Icon name={'verified'} className={'fs-1 text-warning'} />
                                : <Icon name={'verified'} className={'fs-1 text-primary'} />
                            } */}

                        </span>
                    </div>
                    <span className="text-gray-600 fw-semibold d-block fs-5">{userSettings?.[0].Company ?? ""}</span>


                    {/* {(languages != null) && languages.length > 0 ?
                        <div className={'d-flex flex-wrap align-items-center gap-2 w-100 my-1 text-gray-200 '}>
                            <i className="las la-globe-europe fs-2x"></i>
                            {languages.map((lang) => (
                                <div key={lang} className={'d-flex gap-1 align-items-center '}>
                                    <div className={'badge p-1 fs-8 badge-light border border-light-dark'}>
                                        {lang}
                                    </div>
                                </div>
                            ))
                            }
                        </div> : null} */}


                    {Boolean(previewUser.About) && <div className={'rounded rounded-sm bg-gray-200 p-2 pt-4 ps-4 mt-4 lh-1'}>
                        <div className='fw-semi fs-6 text-gray-700 markdown-container mb-1  fw-semibold ls-1' style={{ textIndent: "1em" }}>
                            <Markdown >
                                {previewUser.About ?? "Hey there!I am using BSO Club!"}
                            </Markdown>
                        </div>
                    </div>}

                </div>



            </div >
            <Modal
                open={previewOpen}
                footer={null}
                onCancel={() => {
                    setPreviewOpen(false)
                }}
                closeIcon={
                    <CloseIconBasic onCancel={() => {
                        setPreviewOpen(false)
                    }} cls={''} style={{ top: '-5px', right: '-5px' }} />
                }
                centered
                styles={{
                    body: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '50vh'
                    }
                }}
            >
                <img
                    alt="example"
                    style={{ maxWidth: '100%', maxHeight: '100%' }}
                    src={imageUrl}
                    className='object-fit-cover w-100 h-100'
                />
            </Modal>
        </>
    )
}
